<template>
    <div class="home">
        <div class="max-width">
            <div class="head">
                <div class="logo">
                    <img src="../assets/logo.png"/>
                </div>
                <div class="socials">
                    <a href="https://x.com/playdekingdom" target="_blank">
                        <div class="social">
                            <x_icon :size="'25px'"/>
                        </div>
                    </a>
                    <div class="social">
                        <discord_icon :size="'25px'"/>
                    </div>
                </div>
            </div>
            <div class="game box">
                <div class="game-overlay"></div>

                <div class="bottons">
                    <div class="btn" @click="show_soon = true">Play Now</div>
                    
                    <a href="https://degame.com/en/community/141156?name=DeKingdom&invitationCode=4d2c7be04e3712709549"
                    target="_blank">
                        <div class="btn">Waitlist Signup</div>
                    </a>
                </div>

            </div>
            <!-- <div class="name">
                DEKINGDOM
            </div> -->
        </div>

        <soon_vue v-if="show_soon" @close="show_soon = false"/>
        <signup_vue v-if="show_signup" @close="show_signup = false"/>
    </div>
</template>

<script setup>

import x_icon from '@/icons/x.vue';
import discord_icon from '@/icons/discord.vue';
// import telegram_icon from '@/icons/telegram.vue'

import soon_vue from '@/components/soon.vue';
import signup_vue from '@/components/waitlist/signup.vue';
import { ref } from 'vue';

const show_soon = ref(false)
const show_signup = ref(false)

</script>


<style scoped>
.home {
    height: 100vh;
    width: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    background-color: rgb(12, 12, 26);
}
.max-width {
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo img {
    width: 150px;
}
.socials {
    display: flex;
    align-items: center;
    gap: 20px;
}
.social {
    fill: white;
}

.game {
    position: relative;

    border-radius: 20px;

    flex: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    background: url('../assets/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.game-overlay {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    padding: 20px;
    box-sizing: border-box;

    background: linear-gradient(to left, rgb(46, 0, 0), rgb(10, 16, 36));
    
    background: url('../assets/bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .8;
}


.box {
    /* width: 60vmin; */
    /* height: 50vmin; */
    /* display: grid; */
    /* place-content: center; */
    color: white;
    /* text-shadow: 0 1px 0 #000; */
    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle), #213, #112 5%, #112 60%, #213 95%);
    border: solid 5px transparent;
    border-radius: 2em;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
}
 @keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}
.box:hover {
	animation-play-state: paused;
}
 @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

.bottons {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
    width: 100%;
}
.btn {
    background-color: rgb(12, 12, 26);
    /* background-color: #08f; */
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    
    font-family: "Ceviche One", system-ui;
    font-size: 30px;
    border: 3px solid white;
    cursor: pointer;
}


.name {
    
    font-family: "Ceviche One", system-ui;
    font-size: 50px;

    animation: swap-color 3s infinite linear;
}
@keyframes swap-color {
    0% {
        color: green;
    }
    20% {
        color: red;
    }
    40% {
        color: purple;
    }
    60% {
        color: rgb(255, 0, 187);
    }
    80% {
        color: blue;
    }
    100% {
        color: yellowgreen;
        transform: scale(1.5);
    }
}
</style>