<template>
    <div class="loading" :style="{'height': height}">
        <div class="spin"></div>
    </div>
</template>

<script>
export default {
    props: ['height']
}
</script>

<style scoped>
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spin {
        
        width: 20px;
        height: 20px;
        border-top: 2px solid white;
        border-left: 2px solid white;
        animation: spinner .9s infinite linear;
        border-radius: 50%;
    }
    @keyframes spinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>