import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)

import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost/dekingdom/index.php/'
    baseURL: 'https://api.dekingdom.xyz/index.php/'
});

app.use(VueAxios, axios_instance)
app.provide('axios', app.config.globalProperties.axios)


app.mount('#app')
