<template>
    
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ceviche+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

#app {
    
    font-family: "Space Grotesk", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}
body {
    margin: 0;
    padding: 0;
}
input, textarea {
    
    font-family: "Space Grotesk", sans-serif;
}
a {
    color: inherit;
    text-decoration: none;
}

</style>
