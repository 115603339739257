<template>
    <div class="popup">
        <div class="overlay" @click="emit('close')"></div>
        <div class="box">
            <div class="title">De Kingdom</div>
            <div class="text">Game play will be available after waitlist has ended</div>
        </div>
    </div>
</template>

<script setup>

import { defineEmits } from "vue";

const emit = defineEmits(['close'])



</script>

<style scoped>
.popup {
    position: fixed;
    z-index: 4;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    padding: 20px;
    box-sizing: border-box;


    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);

}
.box {
    position: relative;
    max-width: 400px;
    width: 100%;

    border: 3px solid white;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    
    background-color: rgb(12, 12, 26);
    color: white;
    /* margin-top: auto; */
    animation: pop .5s;
}
@keyframes pop {
    from {
        transform: scale(1.2);
        opacity: .5;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}


.title {

    font-family: "Ceviche One", system-ui;
    font-size: 30px;
    margin-bottom: 20px;
}

</style>