<template>
    <div class="popup">
        <div class="overlay" @click="emit('close')"></div>
        <div class="box">
            
            <div v-if="user">
                <div v-if="user.email_verified">
                    <div class="title">Waitlist Details</div>
                    <div class="detail">
                        <div class="key">Email</div>
                        <div class="value">{{ user.email }}</div>
                    </div>
                    <div class="detail">
                        <div class="key">Username</div>
                        <div class="value">{{ user.username }}</div>
                    </div>
                    <div class="detail">
                        <div class="key">Referral Link</div>
                        <div class="value">https://dekingdom.xyz/?ref={{ user.refferal_code }}</div>
                    </div>
                    <div class="detail">
                        <div class="key">Referrals</div>
                        <div class="value">{{ user.referrals }}</div>
                    </div>

                </div>
                <div v-else>
                    <div class="title">Verify your email</div>
                    <div class="text">Enter the verfication code sent to your email adddress {{ email }}</div>
                    <div class="input">
                        <input type="text" placeholder="Enter your email address" v-model="code">
                        <div class="err" v-if="code_err">The code you entered is incorrect</div>
                    </div>
                    <loading_vue :height="'46px'" v-if="loading"/>
                    <div class="btn" v-else @click="verify()">Verify</div>
                </div>
            </div>
            <div v-else>
                <div class="title">Waitlist Signup</div>
                <div class="input">
                    <input type="text" placeholder="Enter your email address" v-model="email">
                </div>
                <div class="input">
                    <input type="text" placeholder="Pick a username" v-model="username">
                    <div class="err" v-if="username_err">This username has been taken</div>
                </div>
                <!-- <div class="done" v-if="done">
                    Your email is now on the waitlist
                </div> -->
                <loading_vue :height="'46px'" v-if="loading"/>
                <div class="btn" v-else @click="signup()">Signup</div>
            </div>

        </div>
    </div>
</template>

<script setup>
import loading_vue from "../loading.vue";

import { defineEmits, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits(['close'])
const axios = inject('axios')
const route = useRoute()

const loading = ref(false)
const email = ref('')
const username = ref('')
const username_err = ref(false)
// const done = ref(false)

const user = ref(null)

const code = ref('')
const code_err = ref(false)

const referral = ref('')


onMounted(() => {

    let fullpath = route.fullPath
    if (fullpath.includes('?')) {
        let query = fullpath.split('?')[1]

        if (query.includes('=')) {
            referral.value = query.split('=')[1]
        }

    }


    let u = localStorage.getItem('user')
    if (u) {
        user.value = JSON.parse(u)

        email.value = user.value.email
        
        let form = new FormData()
        form.append('email', user.value.email)
        form.append('username', user.value.username)

        axios.post('/waitlist/signup', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                user.value = r.user

                localStorage.setItem('user', JSON.stringify(r.user))
            }
        })
    }

})

const signup = () => {
    if (email.value == '' || username.value == '') { return }

    loading.value = true
    // done.value = false

    let form = new FormData()
    form.append('email', email.value)
    form.append('username', username.value)

    axios.post('/waitlist/signup', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            user.value = r.user
            loading.value = false

            localStorage.setItem('user', JSON.stringify(r.user))
            // done.value = true
        } else {
            if (r.username) {
                loading.value = false
                username_err.value = true
                setTimeout(() => {
                    username_err.value = false
                }, 2000);
            }
        }
    })
}

const verify = () => {
    if (code.value == '') { return }

    loading.value = true
    code_err.value = false

    let form = new FormData()
    form.append('email', email.value)
    form.append('code', code.value)
    form.append('referral', referral.value)

    axios.post('/email/verify', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            loading.value = false
            user.value.email_verified = true
            
            localStorage.setItem('user', JSON.stringify(user.value))
        } else {
            if (r.code) {
                code_err.value = true
                loading.value = false
            }
        }
    })

}


</script>

<style scoped>
.popup {
    position: fixed;
    z-index: 4;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    padding: 20px;
    box-sizing: border-box;


    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);

}
.box {
    position: relative;
    max-width: 400px;
    width: 100%;

    border: 3px solid white;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    
    background-color: rgb(12, 12, 26);
    color: white;
    /* margin-top: auto; */
    animation: pop .5s;
}
@keyframes pop {
    from {
        transform: scale(1.2);
        opacity: .5;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}


.title {

    font-family: "Ceviche One", system-ui;
    font-size: 30px;
    margin-bottom: 20px;
}
.input {
    margin-bottom: 20px;

}
input {
    padding: 15px;
    border-radius: 10px;
    border: 3px solid rgb(255, 255, 255);
    /* border: none; */
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    color: white;
    outline: none;
}
input::placeholder {
    color: white;
}
.err {
    background-color: tomato;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 10px;
}
.btn {
    background-color: #08f;
    text-align: center;
    border-radius: 10px;
    padding: 13px;
    cursor: pointer;
    font-weight: 500;
}
.done {
    background-color: black;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
    animation: dpop .5s;
}
@keyframes dpop {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.text {
    font-size: 14px;
    margin-bottom: 20px;
}

.detail {
    margin-bottom: 15px;
}
.key {
    font-size: 14px;
}
.value {
    font-weight: 600;
    user-select: all;
}
</style>